;(function ($) {

    'use strict';

    $(function () {

        // инициализация работы coder-dropdown
        $('coder-dropdown').not('[skip-init]').each(function (i, dropdown) {
            $(dropdown).coderDropdown('init');
        });

        /*** EVENTS ***/

        // при получении события reloadPjax, обновляем pjax контейнер
        $(document).on('reloadPjax', function (e, containers, options) {

            if (!Array.isArray(containers)) {
                containers = [containers];
            }

            var actionsChain = Promise.resolve();

            // организовываем поочереднный вызов асинхорнных функций
            containers.map((id) => {
                actionsChain = actionsChain.then(() => {
                    return new Promise((resolve) =>     {
                        $(document).on('pjax:complete', '#' + id, function (xhr) {
                            resolve();
                        });

                        var container = '#' + id;

                        var params = {container: container};

                        if ($(container).length == 0) {
                            resolve('container ' + container + ' was not found');
                        }

                        var data = $(container).data();

                        params.push = !!(data.pjaxPushState != undefined);

                        params.replace = !!(data.pjaxReplaceState != undefined);

                        if (data.pjaxScrollto) {
                            params.scrollTo = data.pjaxScrollto;
                        }

                        if (data.pjaxTimeout) {
                            params.timeout = data.pjaxTimeout;
                        }

                        if (data.pjaxUrl) {
                            params.url = data.pjaxUrl;
                        }

                        $.extend(params, options);


                        // удаляем из параметров url те которые пришли в params.data
                        if (params.url == window.location.url && window.location.search.length > 1 && params.data != undefined) {
                            var queryString = $.fn.jsHelpers('parseQueryString', decodeURIComponent(window.location.search.substr(1)));

                            // удаляем совпадения
                            $.each(params.data, function(index, val) {
                                if (queryString[index] != undefined) {
                                    delete queryString[index];
                                }
                            });

                            // строим новый url
                            params.url = window.location.origin + window.location.pathname;

                            if (Object.keys(queryString).length > 0) {
                                params.url += '?' +  Object.keys(queryString).map(function(key){
                                        return encodeURIComponent(key) + '=' + encodeURIComponent(queryString[key]);
                                    }).join('&');
                            }

                            // если в options.data пришел пустой json, то убираем все параметры из строки
                            if (options != undefined && Object.keys(options.data).length == 0) {
                                params.url = window.location.origin + window.location.pathname;
                            }
                        }

                        // убираем пустые поля
                        if (params.data != undefined) {
                            _.each(params.data, function (v, i) {
                                if (v == '' || i == "{$csrf}") {
                                    delete params.data[i];
                                }
                            } );
                        }

                        $.pjax.reload(params);
                    });
                });
            });

            actionsChain.then(() => {
                // console.log('complete all');
            });

        });

        // открытие модальных окон при нажатии на ссылки с классом open-modal
        $(document).on('click', '.open-modal', (e) => {
            e.preventDefault();
            e.stopPropagation();

            let url = '';
            let target = $(e.target).closest('.open-modal');

            // убираем фокус с кнопки
            $(e.target).blur();

            if (target.attr('href')) {
                url = target.attr('href');
            } else if (target.data('url')) {
                url = target.data('url');
            }

            if (url) {
                $.fn.coderModal('open', url, target.data());
            }

        });

        // закрытие модальных окон при нажатии на ссылки с классом close-modal
        $(document).on('click', '.close-modal', (e) => {
            e.preventDefault();
            e.stopPropagation();

            $.fn.coderModal('close');
        });

        // действия после открытия модального окна
        $(document).on('coder-modal:opened', '.coder-modal', (e) => {
            $(e.target).limitless('initStyles');

            $(e.target).find('.froala-editor').each(function (i, el) {
                $(el).froalaEditor($(el).data('jsoptions'));
            });

            // инициализация работы coder-dropdown в модальных окнах
            $(e.target).find('coder-dropdown').not('[skip-init]').each(function (i, dropdown) {
                // удаляем из списка, на случай если модальное окно открылось уже не в первый раз
                delete $.fn.coderDropdown.dropdowns[dropdown.id + '__dropdown'];

                $(dropdown).coderDropdown('init');
            });
        });

        // действия после закрытия модального окна
        $(document).on('coder-modal:closed', '.coder-modal', () => {

        });

        // действия после обновления pjax контейнеров
        $(document).on('pjax:complete', '[data-pjax-container]', function (event) {
            $(event.target).limitless('initStyles');

            // инициализация работы coder-dropdown после обновления pjax контейнера
            $(event.target).find('coder-dropdown').not('[skip-init]').each(function (i, dropdown) {
                // удаляем из списка, на случай если pjax обновился уже не в первый раз
                delete $.fn.coderDropdown.dropdowns[dropdown.id + '__dropdown'];

                $(dropdown).coderDropdown('init');
            });
        });

        $(window).on('beforeunload', function (e) {
            // если нужно что то сделать перед закрытием, то делаем здесь
            // если нужно предупредить пользователя, то делаем return
            // return;
        });


        /**
         * если таблица находиться внутри pjax, то для аякс запросов
         * будем использовать ссылки или кнопки с классом .action
         * и при указании ссылки для кнопки, отправиться запрос по ссылке
         * и после получения success, перегрузиться таблица, и если был определен
         * вызов еще других функций то указываем через параметр data-on-success
         */
        $(document).on('click', '[data-pjax-container] .action', function (event) {
            event.preventDefault();
            event.stopPropagation();

            var button = $(this);

            var sendAjax = function (url, element, onSuccess) {

                if (url) {
                    $.post({
                        url: url,
                        success: function (data, textStatus, jqXHR) {

                            var successCallback = $.ajaxSetup()['success'];

                            if (successCallback != undefined) {
                                successCallback(data, textStatus, jqXHR);
                            }

                            if (data.success) {
                                $.pjax.reload({container: '#' + element.closest('[data-pjax-container]').attr('id')});

                                if (onSuccess != undefined) {
                                    eval(onSuccess);
                                }
                            }

                        }
                    });
                }
            };

            var url = false;
            var onSuccess;

            if (button.attr('href') != undefined) {
                url = button.attr('href');
            } else if (button.attr('data-url') != undefined) {
                url = button.attr('data-url');
            }

            if (url) {
                if (button.data('on-success') != undefined) {
                    onSuccess = button.data('on-success');
                }

                if (button.data('confirm-content')) {

                    $.fn.jsHelpers('confirm', button.data(), function () {
                        sendAjax(url, button, onSuccess);
                    });

                } else {
                    sendAjax(url, button, onSuccess);
                }
            }

        });

        // если таблица находиться внутри pjax, то ссылки не переходят, просто так
        $(document).on('click', '[data-pjax-container] .goto', function (event) {
            event.preventDefault();
            event.stopPropagation();

            var url = false;
            var button = $(this);

            if (!button.is('button') && !button.is('a')) {
                button = button.closest('button');
            }

            if (button.attr('href') != undefined) {
                url = button.attr('href');
            } else if (button.attr('data-url') != undefined) {
                url = button.attr('data-url');
            }

            if (url) {
                if (this.target && this.target == '_blank') {
                    window.open(url);
                } else {
                    window.location = url;
                }
            }

        });

        // если таблица находиться внутри pjax, то ссылки не переходят, просто так
        $(document).on('click', '[data-pjax-container] a[data-pjax], [data-pjax-container] button[data-pjax]', function (event) {
            var url = false;
            var button = $(this);

            if (!button.is('button') && !button.is('a')) {
                button = button.closest('button');
            }

            if (button.attr('href') != undefined) {
                url = button.attr('href');
            } else if (button.attr('data-url') != undefined) {
                url = button.attr('data-url');
            }

            if (url) {
                if (button.data('confirm-content')) {
                    event.preventDefault();
                    event.stopPropagation();
                    $.fn.jsHelpers('confirm', button.data(), function () {
                        $.pjax({
                            url,
                            container: "#" + button.closest('[data-pjax-container]').attr('id'),
                            push: false,
                            type: "post"
                        })
                    });
                }
            }

        });

        /**
         * Ловим события клавиатуры, и ищем элементы на которых стоит атрибут
         * data-shortcut с подходящим кодом, и если находит то делаем trigger('click')
         *
         * Примеры
         *
         * <a data-shortcut="ctrl+m">new document</a>
         * <a data-shortcut="ctrl+shift+M">new document</a>
         * <a data-shortcut="ctrl+shift+alt+M">new document</a>
         * <a data-shortcut="ctrl+alt+m">new document</a>
         */
        $(document).on('keydown', (event) => {
            var keys = [];

            if (event.ctrlKey || event.metaKey) {
                keys.push('ctrl');
            }

            if (event.shiftKey) {
                keys.push('shift');
            }

            if (event.altKey) {
                keys.push('alt');
            }

            if (keys) {
                keys.push(event.key);

                var el = $('[data-shortcut="' + keys.join('+') + '"]');

                if (el.length > 0) {
                    event.preventDefault();
                    event.stopPropagation();

                    el.trigger('click');
                }
            }

        })

    });

}(jQuery));